import { BaseEntity } from './BaseEntity';

export enum SupportStatus {
  OPENING = 'opening',
  SOLVED = 'solved'
}

export interface Support extends BaseEntity {
  type: string
  subject: string
  email: string
  message: string
  attachment: string
  attachmentLink: string
  status: SupportStatus
}

export type PartialSupport = Partial<Support>;
